.page {
    margin: 0;
    padding: 0;
    display: block;
}
.content {
    padding: 0;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1;
    /*-webkit-overflow-scrolling: touch;*/
}


.dialog-container .MuiDialog-container {
    flex: 1;
}

.flex-1 {
    flex: 1;
}

.fila {
    height: 99%;
    flex: 1;
}


.chat-msg {
    float: right !important;
    width: fit-content;
    padding: 5px 5px 0px 5px;
    border-radius: 5px;
    max-width: 50%;
    width: auto !important;
    position: relative;
    display: block;
}
.hora{
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-size: 10px;
    color: #aaaaaa;
}
.chat-msg .chat-msg-enviado {
    text-align: left !important;
    float: right !important;
    padding-right: 20px ;
}
.chat-msg .chat-msg-recibido {
    text-align: left !important;
    float: left !important;
    padding: 20px
}
.chat-msg-enviado {
    background-color: #F6F6F6;
    color: #616161;
    width: 60%;
    margin-right: 5px;
    font-size: 14px;
    padding: 10px;
    padding-right: 40px;
}

.chat-msg-recibido {
    background-color: #FFE9E8;
    width: 60%;
    color: #616161;
    margin-left: 5px;
    font-size: 14px;
    padding: 10px;
    padding-right: 40px;
}

.messages-list {
    overflow-y: auto;
    /* height: 500px; */
}
.chat-aden{
    position: relative;
    height: 70vh;
}
.chat-container-aden{
    overflow-y: auto !important;
}
.chat-send-msj{
    height: 15%;
}
.chat-content{
    height: 100%;
    max-height: 100%;
    width: 100% !important;
}
.scroll{
    height: 100%;
    width: 100%;
}
.chat-content>div>div{
    overflow-x: hidden !important;
    padding-right: 30px !important;
    padding-left: 15px !important;
}