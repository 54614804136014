@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css);
@import url(//cdn.quilljs.com/1.2.6/quill.snow.css);
/* @import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css"; */

@font-face {
    font-family: 'VideoJS';
    src: url('https://vjs.zencdn.net/f/1/vjs.eot');
    src: url('https://vjs.zencdn.net/f/1/vjs.eot?#iefix')
            format('embedded-opentype'),
        url('https://vjs.zencdn.net/f/1/vjs.woff') format('woff'),
        url('https://vjs.zencdn.net/f/1/vjs.ttf') format('truetype');
}

:root {
    interpolate-size: allow-keywords;
    --border-radius_circular: 50%;
    --border-radius_rounded: 50px;
    --border-radius_sharp: 30px;
    --border-radius_medium: 20px;
    --border-radius_smooth: 10px;

    --shadow-strong: 0px 3px 6px 0px rgba(0, 0, 0, 0.16),
        0px 3px 6px 0px rgba(0, 0, 0, 0.23);
}

button {
    border: none;
    outline: none;
    background-color: transparent;
}

button:focus {
    outline: none !important;
}

* {
    font-family: 'Lato', sans-serif !important;
    margin: 0;
    padding: 0;
    scrollbar-width: thin !important;
    text-decoration: none;
    scroll-behavior: smooth;
}

@media (max-width: 768px) {
    * {
        font-size: 13px;
    }
}

@media (max-width: 425px) {
    * {
        font-size: 12px;
    }
}

html,
body {
    background-color: #f9f9f9 !important;
    color: #222222;
    scrollbar-color: rgb(200, 200, 200) rgba(0, 0, 0, 0);
}

body {
    height: 100vh;
}

#root {
    min-height: 100vh;
}

.text-danger {
    font-size: 0.7em;
}

/* CHROME SCROLLBAR */
::-webkit-scrollbar {
    width: 0.42vw;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgb(170, 170, 170);
}

.MuiButton-root {
    text-transform: none !important;
}

h1,
h2,
p,
span {
    margin: 0;
}

a {
    color: #b31d15;
}

a:hover {
    text-decoration: none !important;
}

.video-js .vjs-play-control.vjs-playing .vjs-icon-placeholder:before,
.vjs-icon-pause:before {
    display: flex;
    justify-content: center;
    align-items: center;
    content: '\f103';
    font-family: 'VideoJS';
}

.video-js .vjs-mute-control .vjs-icon-placeholder:before,
.vjs-icon-volume-high:before {
    display: flex;
    justify-content: center;
    align-items: center;
    content: '\f107';
    font-family: 'VideoJS';
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before,
.video-js .vjs-play-control .vjs-icon-placeholder:before,
.vjs-icon-play:before {
    display: flex;
    justify-content: center;
    align-items: center;
    content: '\f101';
    font-family: 'VideoJS';
}

.video-js .vjs-picture-in-picture-control .vjs-icon-placeholder:before,
.vjs-icon-picture-in-picture-enter:before {
    display: flex;
    justify-content: center;
    align-items: center;
    content: '\f121';
    font-family: 'VideoJS';
}

.video-js .vjs-fullscreen-control .vjs-icon-placeholder:before,
.vjs-icon-fullscreen-enter:before {
    display: flex;
    justify-content: center;
    align-items: center;
    content: '\f108';
    font-family: 'VideoJS';
}

body
    > iframe[style*='2147483647']:not([id='webpack-dev-server-client-overlay']) {
    display: none;
}

.page {
    margin: 0;
    padding: 0;
    display: block;
}
.content {
    padding: 0;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1 1;
    /*-webkit-overflow-scrolling: touch;*/
}


.dialog-container .MuiDialog-container {
    flex: 1 1;
}

.flex-1 {
    flex: 1 1;
}

.fila {
    height: 99%;
    flex: 1 1;
}


.chat-msg {
    float: right !important;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px 5px 0px 5px;
    border-radius: 5px;
    max-width: 50%;
    width: auto !important;
    position: relative;
    display: block;
}
.hora{
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-size: 10px;
    color: #aaaaaa;
}
.chat-msg .chat-msg-enviado {
    text-align: left !important;
    float: right !important;
    padding-right: 20px ;
}
.chat-msg .chat-msg-recibido {
    text-align: left !important;
    float: left !important;
    padding: 20px
}
.chat-msg-enviado {
    background-color: #F6F6F6;
    color: #616161;
    width: 60%;
    margin-right: 5px;
    font-size: 14px;
    padding: 10px;
    padding-right: 40px;
}

.chat-msg-recibido {
    background-color: #FFE9E8;
    width: 60%;
    color: #616161;
    margin-left: 5px;
    font-size: 14px;
    padding: 10px;
    padding-right: 40px;
}

.messages-list {
    overflow-y: auto;
    /* height: 500px; */
}
.chat-aden{
    position: relative;
    height: 70vh;
}
.chat-container-aden{
    overflow-y: auto !important;
}
.chat-send-msj{
    height: 15%;
}
.chat-content{
    height: 100%;
    max-height: 100%;
    width: 100% !important;
}
.scroll{
    height: 100%;
    width: 100%;
}
.chat-content>div>div{
    overflow-x: hidden !important;
    padding-right: 30px !important;
    padding-left: 15px !important;
}
